import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { NarrowWrapper } from "../components/layout";

function TermsAndConditions() {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <NarrowWrapper>
      <button onClick={history.goBack} className="text-primary text-xl mb-10">
        {t("Back")}
      </button>
      <h3 className="pb-10 text-4xl font-bold break-words">
        {t("Data privacy statement")}
      </h3>
      <p className="my-4">
        {t(
          "The application stores user information securely and confidentially. Its protocol has undergone the appropriate ethical review and has received approval (2021-2118-Bef) from the Ethik-Kommission affiliated with Universitatsklinikum (Ethics Committee) at Friedrich Schiller University Jena. The approval is valid for the full duration of the project, including the storage, analysis, and dissemination of the data even after the period of data collection is concluded. To note, this project also complies with the EU General Data Protection Regulation (Art. 13).",
        )}
      </p>
      <p className="my-4">
        {t(
          "In order to enable continuous playing, the application requires users to create an account by generating an alias of choice. The application does not require nor record information such as names, geolocalization tags, or UDID/UUID information. The application does however collect email addresses as part of the account creating process. We collect this information for the sole purpose of enabling username and/or password retrieval. We do not use this information to communicate directly with users, nor do we share this information to any third parties. Additionally, users will be asked to provide information regarding their language background and levels of literacy in those languages.",
        )}
      </p>
      <p>
        {t(
          "The process of creating an account will involve users agreeing to Terms and Conditions allowing use of user data. This consent forms the legal basis for data processing, according to Art. 6. Para. 1, Character A, or Art. 9. Para 2 lit. a GDPR. Participants are free to opt out at any point during the period of data collection.",
        )}
      </p>
      <p className="my-4">
        {t(
          "Although the application does not collect geolocalization information, it does check users’ IP addresses, but this information is not stored. Additionally, the application will record timestamps for various points at which users play different phases of the game (e.g., when a rule is created or tested). Furthermore, within the game, users do not interact directly with other users and are only identified throughout the game (e.g., on the leaderboard) by their aliases, making identification of users outside the game impossible. Any data stored are hosted exclusively on the secure servers of the Max Planck Society.",
        )}
      </p>
      <p className="my-4">
        {t(
          "Fully anonymized data will be disseminated to the scientific community for research purposes only; no personal data will be shared under any circumstances. The researchers of the Max Planck Society thus will access a fully anonymized set of data, which subsequently will be made publicly available to other researchers after an initial embargo period.",
        )}
      </p>
    </NarrowWrapper>
  );
}

export default TermsAndConditions;
